@tailwind base;
@tailwind components;
@tailwind utilities;

/*@keyframes float {*/
/*  0%, 100% {*/
/*    transform: translateY(0);*/
/*  }*/
/*  50% {*/
/*    transform: translateY(-10px);*/
/*  }*/
/*}*/

/*@keyframes pulse {*/
/*  0%, 100% {*/
/*    opacity: 1;*/
/*    transform: scale(1);*/
/*  }*/
/*  50% {*/
/*    opacity: 0.9;*/
/*    transform: scale(1.03);*/
/*  }*/
/*}*/

/*@keyframes gradient {*/
/*  0% {*/
/*    background-position: 0% 50%;*/
/*  }*/
/*  50% {*/
/*    background-position: 100% 50%;*/
/*  }*/
/*  100% {*/
/*    background-position: 0% 50%;*/
/*  }*/
/*}*/

/*@keyframes blob {*/
/*  0%, 100% {*/
/*    transform: translate(0, 0) scale(1);*/
/*  }*/
/*  25% {*/
/*    transform: translate(15px, -20px) scale(1.05);*/
/*  }*/
/*  50% {*/
/*    transform: translate(-15px, 15px) scale(0.95);*/
/*  }*/
/*  75% {*/
/*    transform: translate(20px, 20px) scale(1.02);*/
/*  }*/
/*}*/

/*.animate-float {*/
/*  animation: float 3s ease-in-out infinite;*/
/*}*/

/*.animate-pulse-slow {*/
/*  animation: pulse 4s ease-in-out infinite;*/
/*}*/

/*.animate-gradient {*/
/*  background-size: 200% 200%;*/
/*  animation: gradient 8s ease infinite;*/
/*}*/

/*.animate-blob {*/
/*  animation: blob 8s ease-in-out infinite;*/
/*}*/

/*.animation-delay-2000 {*/
/*  animation-delay: 2s;*/
/*}*/

/*.animation-delay-4000 {*/
/*  animation-delay: 4s;*/
/*}*/

/*!* Glass effect *!*/
/*.glass-effect {*/
/*  backdrop-filter: blur(8px);*/
/*  background: rgba(255, 255, 255, 0.85);*/
/*  border: 1px solid rgba(255, 255, 255, 0.2);*/
/*}*/

/*!* Gradient text *!*/
/*.gradient-text {*/
/*  background: linear-gradient(45deg, #3564dc, #359cdc);*/
/*  -webkit-background-clip: text;*/
/*  background-clip: text;*/
/*  color: transparent;*/
/*}*/

/*!* Card hover effects *!*/
/*.hover-card {*/
/*  transition: all 0.3s ease;*/
/*  background: white;*/
/*  border: 1px solid rgba(255, 126, 51, 0.1);*/
/*}*/

/*.hover-card:hover {*/
/*  transform: translateY(-5px);*/
/*  box-shadow: 0 15px 30px rgba(255, 126, 51, 0.08);*/
/*}*/

/*!* Button styles *!*/
/*.gradient-button {*/
/*  background: linear-gradient(45deg, #3564dc, #359cdc);*/
/*  transition: all 0.3s ease;*/
/*}*/

/*.gradient-button:hover {*/
/*  transform: translateY(-2px);*/
/*  box-shadow: 0 8px 15px rgba(255, 126, 51, 0.15);*/
/*}*/

/*!* Animated border *!*/
/*.animated-border {*/
/*  position: relative;*/
/*  overflow: hidden;*/
/*}*/

/*.animated-border::after {*/
/*  content: '';*/
/*  position: absolute;*/
/*  bottom: 0;*/
/*  left: 0;*/
/*  width: 100%;*/
/*  height: 1.5px;*/
/*  background: linear-gradient(90deg, #3564dc, #359cdc);*/
/*  transform: scaleX(0);*/
/*  transform-origin: left;*/
/*  transition: transform 0.3s ease;*/
/*}*/

/*.animated-border:hover::after {*/
/*  transform: scaleX(1);*/
/*}*/

/*!* Smooth scroll behavior *!*/
/*html {*/
/*  scroll-behavior: smooth;*/
/*}*/

/*!* Custom scrollbar *!*/
/*::-webkit-scrollbar {*/
/*  width: 8px;*/
/*}*/

/*::-webkit-scrollbar-track {*/
/*  background: #f8f8f8;*/
/*}*/

/*::-webkit-scrollbar-thumb {*/
/*  background: #359cdc;*/
/*  border-radius: 4px;*/
/*}*/

/*::-webkit-scrollbar-thumb:hover {*/
/*  background: #359cdc;*/
/*}*/

/* index.css */

/*!* 通用颜色 *!*/
/*:root {*/
/*  --background-color: #0d0d0d; !* 深色背景 *!*/
/*  --text-color: #f5f5f5; !* 浅色文本 *!*/
/*  --highlight-color: #00bcd4; !* 高级点缀色：青色 *!*/
/*  --button-bg-color: #ffffff; !* 按钮背景色 *!*/
/*  --button-text-color: #0d0d0d; !* 按钮文字色 *!*/
/*  --button-hover-bg-color: #00bcd4; !* 按钮悬浮色 *!*/
/*  --border-color: #333333; !* 浅灰色边框 *!*/
/*}*/

/*!* 主体背景与文本颜色 *!*/
/*body {*/
/*  background-color: var(--background-color);*/
/*  color: var(--text-color);*/
/*}*/

/*!* 按钮样式 *!*/
/*button {*/
/*  background-color: var(--button-bg-color);*/
/*  color: var(--button-text-color);*/
/*}*/

/*button:hover {*/
/*  background-color: var(--button-hover-bg-color);*/
/*  color: var(--button-text-color);*/
/*}*/

/*!* 链接和悬浮状态 *!*/
/*a {*/
/*  color: var(--highlight-color);*/
/*}*/

/*a:hover {*/
/*  color: #ff8f00; !* 橙色悬浮效果 *!*/
/*}*/

/*!* 边框和卡片背景 *!*/
/*.card {*/
/*  background-color: var(--background-color);*/
/*  border: 1px solid var(--border-color);*/
/*  color: var(--text-color);*/
/*}*/
